<template>
  <div style="position: relative">
    <profile-image
      :user="profile"
      :size="120"
      class="profile-img img-thumbnail rounded-circle"
    ></profile-image>
    <input
      type="file"
      style="display: none"
      ref="picInput"
      accept="image/*"
      @change="handleFile"
    />
    <div class="camera-icon rounded-circle px-2" v-if="user.id === profile.id">
      <i class="bi bi-camera-fill fs-5" @click="$refs.picInput.click()"></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  name: "ProfileAvatar",
  components: {
    ProfileImage
  },
  data() {
    return {
      imageObj: null
    }
  },
  computed: {
    ...mapState("user", ["user", "profile"])
  },
  methods: {
    ...mapActions("user", ["editUser", "uploadProfileImage"]),
    handleFile(e) {
      this.imageObj = e.target.files[0]

      let formData = new FormData()
      formData.append("avatar", this.imageObj)
      this.uploadProfileImage({
        type: "avatar",
        userId: this.profile.id,
        data: formData
      })
    }
  }
}
</script>

<style scoped>
.profile-img {
  margin-top: -4rem;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.camera-icon {
  position: absolute;
  top: 20px;
  right: -8px;
  background-color: white;
  cursor: pointer;
}
</style>
