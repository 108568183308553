<template>
  <form action="" class="">
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Company</label>
      <input type="text" class="form-control" />
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Position</label>
      <input type="text" class="form-control" />
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Description</label>
      <textarea type="text" class="form-control" rows="3"></textarea>
    </div>

    <button class="mt-3 btn text-primary d-flex align-items-center">
      <spa class="me-2"><i class="bi bi-plus fs-4"></i></spa>
      <span class="text-black">Add New</span>
    </button>

    <button class="btn btn-primary mt-3 px-5 rounded-pill">Save</button>
  </form>
</template>
