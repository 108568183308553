<template>
  <div
    class="modal fade"
    id="bioModal"
    tabindex="-1"
    aria-labelledby="bioModalLabel"
    aria-hidden="true"
    ref="bioModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="p-3 border-0">
          <div class="modal-header border-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="bioTabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: section == 'personalInfo' }"
                  id="personal-info-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-personalInfo"
                  type="button"
                  role="tab"
                  aria-controls="nav-personalInfo"
                  :aria-selected="section == 'personalInfo'"
                  @click="$emit('updateActive', 'personalInfo')"
                >
                  Personal Info
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: section == 'address' }"
                  id="address-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-address"
                  type="button"
                  role="tab"
                  aria-controls="nav-address"
                  :aria-selected="section == 'address'"
                  @click="$emit('updateActive', 'address')"
                >
                  Address
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: section == 'education' }"
                  id="education-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-education"
                  type="button"
                  role="tab"
                  aria-controls="nav-education"
                  :aria-selected="section == 'education'"
                  @click="$emit('updateActive', 'education')"
                >
                  Education
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: section == 'job' }"
                  id="job-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-job"
                  type="button"
                  role="tab"
                  aria-controls="nav-job"
                  :aria-selected="section == 'job'"
                  @click="$emit('updateActive', 'job')"
                >
                  Job
                </button>
              </li>
            </ul>
            <div class="tab-content" id="bioTabContent">
              <div
                class="tab-pane fade"
                :class="{
                  active: section == 'personalInfo',
                  show: section == 'personalInfo'
                }"
                id="nav-personalInfo"
                role="tabpanel"
                aria-labelledby="personal-info-tab"
              >
                <edit-personal-info></edit-personal-info>
              </div>
              <div
                class="tab-pane fade"
                :class="{
                  active: section == 'address',
                  show: section == 'address'
                }"
                id="nav-address"
                role="tabpanel"
                aria-labelledby="address-tab"
              >
                <edit-address></edit-address>
              </div>
              <div
                class="tab-pane fade"
                :class="{
                  active: section == 'education',
                  show: section == 'education'
                }"
                id="nav-education"
                role="tabpanel"
                aria-labelledby="education-tab"
              >
                <edit-education></edit-education>
              </div>
              <div
                class="tab-pane fade"
                :class="{
                  active: section == 'job',
                  show: section == 'job'
                }"
                id="nav-job"
                role="tabpanel"
                aria-labelledby="job-tab"
              >
                <edit-job></edit-job>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditAddress from "./EditAddress.vue"
import EditPersonalInfo from "./EditPersonalInfo.vue"
import EditEducation from "./EditEducation.vue"
import EditJob from "./EditJob.vue"
export default {
  components: {
    EditAddress,
    EditPersonalInfo,
    EditEducation,
    EditJob
  },
  props: {
    section: { type: String, default: "personalInfo" }
  }
}
</script>

<style scoped>
.tab-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
/* Styling for active tab */
.tab-header_active {
  color: #ff0bb9;
  border-bottom: 2px solid #ff0bb9;
}
.tab-header:hover {
  color: #ff0bb9;
}
</style>
