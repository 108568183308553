<template>
  <div>
    <div class="bg-white rounded-3 shadow-sm p-2">
      <div class="py-2 px-3" style="border-bottom: 1px solid #dadada">
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="following-text mt-2">Mesages</h1>
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z"
            />
          </svg>
        </div>
        <div class="d-flex align-items-center justify-content-center mb-2">
          <!-- search-bar -->
          <div
            class="d-flex align-items-center justify-content-center search-bar px-4 mt-2"
          >
            <svg
              width="18"
              height="18"
              fill="currentColor"
              color="#DADADA"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
            <input
              type="text"
              class="search-box py-1 px-2"
              placeholder="Search for messages"
            />
          </div>
        </div>
      </div>
      <!-- messages -->
      <div
        class="d-flex align-items-center justify-content-between mt-3"
        v-for="message in messages"
        :key="message.id"
      >
        <div class="d-flex align-items-center">
          <img
            :src="require('@/assets/img/user-5.jpg')"
            class="profile-img d-block"
            alt="profile image"
          />
          <div class="ms-3">
            <h5 class="poster-name">
              {{ message.user.first_name }} {{ message.user.last_name }}
              <i
                class="bi bi-patch-check-fill text-success ms-1"
                v-if="message.user.email_verified_at != null"
              ></i>
            </h5>
            <h5 class="poster-user-name">Active {{ message.active }}</h5>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-center bg-primary rounded-pill px-2 py-1 message-count"
        >
          <h3 class="text-white text-center message-number mt-2">
            {{ message.message_count }}
          </h3>
        </div>
      </div>
      <div class="text-center mt-3" v-if="messages.length == 0">No message</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      messages: [
        // {
        //   id: 1,
        //   user: {
        //     first_name: "Lina",
        //     last_name: "Grey",
        //     image_path: "@/assets/img/user-5.jpg"
        //   },
        //   message_count: 3,
        //   active: "1h ago"
        // }
      ]
    }
  }
}
</script>

<style scoped>
.poster-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.poster-user-name {
  margin-top: -0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}
.last-seen {
  color: #a5a5a5;
  font-size: 0.7rem;
}
.profile-img {
  width: 63px;
  height: 63px;
  border-radius: 100px;
  aspect-ratio: 1/1;

  object-fit: cover;
}
.message-count {
  height: 25px;
}
.message-indicator {
  height: 10px;
  width: 10px;
}
.message-number {
  font-size: 0.8rem;
}

.following-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.search-bar {
  border: 1px solid #eeeeee;
  border-radius: 41px;
  width: 17rem;
}
.search-box {
  border: none;
  width: 15rem;
  height: 55px;
  outline: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}
::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #dadada;
}
</style>
