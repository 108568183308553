<template>
  <div>
    <div class="gallery-wrapper">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-start">
          <h2 class="card-header-text">Gallery</h2>
          <h2 class="pics-count">{{ imgs.length }} Pics</h2>
        </div>
        <div v-if="user.id == profile.id">
          <input
            type="file"
            style="display: none"
            ref="picInput"
            accept="image/*"
            @change="handleFile"
          />
          <div
            class="btn btn-primary text-white"
            @click="$refs.picInput.click()"
          >
            Upload
          </div>
        </div>
      </div>
      <div class="gallery-wall">
        <img
          v-for="(img, index) in imgs"
          :key="index"
          :src="img"
          class="gallery-img"
          @click="() => showImg(index)"
        />
      </div>
      <div class="grid">
        <button class="btn text-primary mt-3" @click="loadNextPage">
          See More
        </button>
      </div>
    </div>

    <!-- all props & events -->
    <vue-easy-lightbox
      scrollDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox"
import { mapActions, mapState } from "vuex"

export default {
  name: "Gallery",
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      visible: false,
      index: 0
    }
  },
  mounted() {
    this.getUserPhotos({ userId: this.profile.id, page: 1 })
  },
  computed: {
    ...mapState("user", ["user", "profile"]),
    ...mapState("photo", ["gallery", "page"]),
    imgs() {
      return this.gallery.map((photo) => photo.image_path)
    }
  },
  methods: {
    ...mapActions("photo", ["getUserPhotos", "uploadPhoto"]),
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    handleFile(e) {
      let imageObj = e.target.files[0]

      let formData = new FormData()
      formData.append("image_path", imageObj)
      this.uploadPhoto(formData)
    },
    loadNextPage() {
      this.getUserPhotos({
        userId: this.profile.id,
        page: this.page.current_page + 1
      })
    }
  }
}
</script>

<style scoped>
.gallery-wall {
  display: column;
  columns: 3;
  gap: 1em;
  margin: auto;
}
.gallery-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-top: 1rem;
  padding: 16px;
}
.gallery-img {
  width: 100%;
}
.gallery-wall > * {
  break-inside: avoid;
  margin-bottom: 1em;
}
.card-header-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #04295f;
}
.pics-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 39px;
  color: #c4c4c4;
  margin-left: 10px;
}
</style>
