<template>
  <div class="overflow-hidden py-2 mt-8 mt-lg-4" :key="$route.params.id">
    <div class="row mt-1 mt-lg-5 pt-1 pt-lg-4 px-2 px-lg-5">
      <div class="alert alert-danger" role="alert" v-if="!isVerified">
        <div class="row align-items-center">
          <p class="col-12 col-md-8">{{ verificationMessage }}</p>
          <button
            class="col-12 col-md-4 btn btn-primary text-white"
            @click="sendEmailVerificationLink"
          >
            Resend link
          </button>
        </div>
      </div>

      <profile-header></profile-header>
      <div class="col-md-3 d-none d-lg-block">
        <contacts></contacts>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Contacts from "@/components/Contacts.vue"
import ProfileHeader from "@/components/profile/ProfileHeader.vue"
export default {
  components: {
    Contacts,
    ProfileHeader
  },
  data() {
    return {
      verificationMessage:
        "Your email is not verified. Check your inbox for a mail with the link or send a new one"
    }
  },
  async beforeRouteUpdate(to, from) {
    const profileId = to.params.id
    if (profileId != from.params.id) {
      await this.getUser(profileId)
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  },
  async mounted() {
    const profileId = this.$route.params.id
    await this.getUser(profileId)
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  },
  computed: {
    ...mapState("user", ["user"]),
    isVerified() {
      return this.user ? this.user.email_verified_at != null : false
    }
  },
  methods: {
    ...mapActions("user", ["getUser"]),
    ...mapActions("verify", ["sendEmailVerificationLink"])
  }
}
</script>
