<template>
  <form action="" class="">
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">School</label>
      <input type="text" class="form-control" />
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Qualification</label>
      <input type="text" class="form-control" />
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Year</label>
      <div class="row g-3">
        <div class="col">
          <input type="number" class="form-control" placeholder="Start" />
        </div>

        <div class="col">
          <input type="number" class="form-control" placeholder="Stop" />
        </div>
      </div>
    </div>
    <button class="mt-3 btn text-primary d-flex align-items-center">
      <spa class="me-2"><i class="bi bi-plus fs-4"></i></spa>
      <span class="text-black">Add New</span>
    </button>

    <button class="btn btn-primary mt-3 px-5 rounded-pill">Save</button>
  </form>
</template>
