<template>
  <form @submit.prevent="submitPersonalInfo" v-if="bioObj">
    <div class="row mb-3 align-items-start justify-content-start">
      <div class="col-md-6 my-2 my-lg-0">
        <label class="form-label text-start">First Name</label>
        <input
          type="text"
          class="form-control"
          required
          autocomplete="first_name"
          autofocus
          placeholder="First Name"
          v-model="bioObj.first_name"
        />
      </div>
      <div class="col-md-6 my-2 my-lg-0">
        <label class="form-label text-left">Last Name</label>
        <input
          type="text"
          class="form-control"
          required
          autocomplete="last_name"
          autofocus
          placeholder="Last Name"
          v-model="bioObj.last_name"
        />
      </div>
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Gender</label>
      <select
        class="form-select"
        aria-label="Bio select"
        v-model="bioObj.gender"
      >
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Date of Birth</label>
      <div class="row g-3">
        <div class="col">
          <input
            type="number"
            min="1"
            max="31"
            class="form-control"
            placeholder="Day"
            v-model="dobDay"
          />
        </div>
        <div class="col">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="dobMonth"
          >
            <option value="0">Month</option>
            <option
              v-for="(month, index) in months"
              :value="index + 1"
              :key="index"
            >
              {{ month }}
            </option>
          </select>
        </div>
        <div class="col">
          <input
            type="number"
            min="1900"
            class="form-control"
            placeholder="Year"
            v-model="dobYear"
          />
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">Joined</label>
      <input
        type="text"
        class="form-control"
        v-model="bioObj.created_at"
        disabled
      />
    </div>
    <button type="submit" class="btn btn-primary mt-3 px-5 rounded-pill">
      Save
    </button>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      bioObj: null,
      dobDay: null,
      dobMonth: null,
      dobYear: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    }
  },
  mounted() {
    this.bioObj = this.profile
    const dob = this.profile.dob.split("-")
    this.dobDay = parseInt(dob[2])
    this.dobMonth = parseInt(dob[1])
    this.dobYear = parseInt(dob[0])
  },
  computed: {
    ...mapState("user", ["profile"])
  },
  methods: {
    ...mapActions("user", ["editUser"]),
    submitPersonalInfo() {
      this.bioObj.dob = this.stringifiedDate()

      let data = {
        id: this.bioObj.id,
        first_name: this.bioObj.first_name,
        last_name: this.bioObj.last_name,
        gender: this.bioObj.gender,
        dob: this.bioObj.dob,
        phone_number: this.bioObj.phone_number,
        about: this.bioObj.about,
        city: this.bioObj.city,
        country: this.bioObj.country,
        hobbies: this.bioObj.hobbies,
        primary_school: this.bioObj.primary_school,
        secondary_school: this.bioObj.secondary_school,
        university: this.bioObj.university
      }
      this.editUser(data)
    },
    stringifiedDate() {
      let newDate = `${this.dobYear}`
      newDate += this.dobMonth < 10 ? `-0${this.dobMonth}` : `-${this.dobMonth}`
      newDate += this.dobDay < 10 ? `-0${this.dobDay}` : `-${this.dobDay}`
      return newDate
    }
  }
}
</script>
