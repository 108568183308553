<template>
  <div class="impression-card">
    <div
      class="p-1 d-flex align-items-center justify-content-center"
      style="border-bottom: 1px solid #ff0bb9"
    >
      <h2 class="profile-text">Profile</h2>
    </div>
    <div class="d-flex align-items-center justify-content-center p-2">
      <div class="d-flex align-items-center mx-2">
        <svg
          width="22"
          height="22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.478 7.92h-5.552l.836-4.022.026-.282c0-.36-.15-.695-.387-.932l-.933-.924-5.79 5.798A1.72 1.72 0 0 0 6.16 8.8v8.8c0 .967.792 1.76 1.76 1.76h7.92c.73 0 1.354-.44 1.618-1.074l2.658-6.204c.079-.202.123-.413.123-.642v-1.76c0-.968-.792-1.76-1.76-1.76Zm0 3.519-2.64 6.16H7.92v-8.8l3.819-3.819-.977 4.699h7.717v1.76ZM.88 8.799H4.4v10.56H.88V8.798Z"
            fill="#6D6D6D"
          />
        </svg>
        <span class="like-text ms-2">1K Likes</span>
      </div>
      <div class="d-flex align-items-center mx-2">
        <svg
          width="20"
          height="19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.106 12.221h5.552l-.836 4.021-.026.282c0 .36.15.695.387.933l.933.924 5.79-5.799a1.72 1.72 0 0 0 .519-1.24v-8.8c0-.968-.792-1.76-1.76-1.76h-7.92c-.73 0-1.355.44-1.619 1.074L.47 8.059c-.079.203-.123.414-.123.643v1.76c0 .967.792 1.76 1.76 1.76Zm0-3.52 2.64-6.159h7.919v8.8l-3.82 3.818.978-4.699H2.106v-1.76Zm17.598 2.64h-3.52V.782h3.52v10.56Z"
            fill="#6D6D6D"
          />
        </svg>
        <span class="like-text ms-2">50 Dislikes</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.impression-card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.profile-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;
}
.like-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;
}
</style>
