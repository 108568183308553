<template>
  <div class="row">
    <div>
      <div class="follow-card shadow-sm">
        <div
          class="d-flex align-items-center justify-content-between py-3 px-5"
          style="border-bottom: 1px solid #dadada"
        >
          <a
            @click="activetab = 1"
            class="tab-header"
            :class="activetab == 1 && 'tab-header_active'"
            >Following</a
          >
          <a
            @click="activetab = 2"
            class="tab-header"
            :class="activetab == 2 && 'tab-header_active'"
            >Followers</a
          >
        </div>
        <div class="py-2">
          <!-- following -->
          <div v-if="activetab === 1" class="px-3">
            <div
              class="d-flex align-items-center justify-content-between px-2 mb-3"
              v-for="userFollow in user_following"
              :key="userFollow.id"
            >
              <div class="d-flex align-items-center justify-content-center">
                <profile-image
                  :user="userFollow.friend"
                  :size="63"
                  class="me-3"
                ></profile-image>
                <div class="ms-2">
                  <h5 class="poster-name">
                    {{ userFollow.friend.first_name }}
                    {{ userFollow.friend.last_name }}
                    <i
                      class="bi bi-patch-check-fill text-success ms-1"
                      v-if="userFollow.friend.email_verified_at != null"
                    ></i>
                  </h5>
                  <div class="d-flex align-items-center">
                    <span class="poster-user-name">
                      @{{ userFollow.friend.first_name }}
                    </span>
                    <span
                      class="poster-user-name ms-2 follow-badge p-1"
                      v-if="isFollower(userFollow.friend_id)"
                    >
                      follows you
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-flex" v-if="user.id != userFollow.friend_id">
                <button
                  class="btn rounded-pill px-3 following-btn"
                  @click="unfollow({ friend_id: userFollow.friend_id })"
                  v-if="isFollowing(userFollow.friend_id)"
                >
                  Unfollow
                </button>
                <button
                  class="btn rounded-pill px-3 follow-btn px-4"
                  @click="follow({ friend_id: userFollow.friend_id })"
                  v-else
                >
                  Follow
                </button>
                <button class="btn btn-sm ms-5">
                  <i class="bi bi-three-dots-vertical fs-5"></i>
                </button>
              </div>
            </div>
          </div>
          <div v-if="activetab === 2" class="px-3">
            <div
              class="d-flex align-items-center justify-content-between px-2 mb-3"
              v-for="follower in user_followers"
              :key="follower.id"
            >
              <div class="d-flex align-items-center justify-content-center">
                <profile-image
                  :user="follower.user"
                  :size="63"
                  class="me-3"
                ></profile-image>
                <div class="ms-2">
                  <h5 class="poster-name">
                    {{ follower.user.first_name }}
                    {{ follower.user.last_name }}
                  </h5>
                  <div class="d-flex align-items-center">
                    <span class="poster-user-name">
                      @{{ follower.user.first_name }}
                    </span>
                    <span
                      class="poster-user-name ms-2 follow-badge p-1"
                      v-if="isFollowing(follower.user_id)"
                    >
                      following
                    </span>
                  </div>
                  <!-- <p class="post-time">12 mutual friends</p> -->
                </div>
              </div>
              <div class="d-flex" v-if="user.id != follower.user_id">
                <button
                  class="btn rounded-pill px-3 following-btn"
                  @click="unfollow({ friend_id: follower.user_id })"
                  v-if="isFollowing(follower.user_id)"
                >
                  Unfollow
                </button>
                <button
                  class="btn rounded-pill px-3 follow-btn px-4"
                  @click="follow({ friend_id: follower.user_id })"
                  v-else
                >
                  Follow
                </button>
                <button class="btn btn-sm ms-5">
                  <i class="bi bi-three-dots-vertical fs-5"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="d-grid px-2 mt-2">
            <button class="btn show-btn">Show more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  components: {
    ProfileImage
  },
  props: { section: String },
  data() {
    return {
      activetab: 1
    }
  },
  mounted() {
    this.activetab = this.section == "following" ? 1 : 2
    this.getUserFollowing(this.profile.id)
    this.getUserFollowers(this.profile.id)
    this.getFollowing()
    this.getFollowers()
  },
  computed: {
    ...mapState("user", ["user", "profile"]),
    ...mapState("follower", [
      "following",
      "followers",
      "user_following",
      "user_followers"
    ]),
    ...mapGetters("follower", ["getFollowingById", "getFollowerById"])
  },
  methods: {
    ...mapActions("follower", [
      "getFollowing",
      "getFollowers",
      "getUserFollowing",
      "getUserFollowers",
      "follow",
      "unfollow"
    ]),
    isFollowing(friend_id) {
      const following = this.getFollowingById(friend_id)
      if (following != null && following != undefined) {
        return true
      }
      return false
    },
    isFollower(user_id) {
      const follower = this.getFollowerById(user_id)
      if (follower != null && follower != undefined) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.follow-card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.following-text {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.edit-text {
  font-size: 0.8rem;
}
.poster-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.poster-user-name {
  margin-top: -0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}
.following-btn {
  font-weight: 600;
  font-size: 12px;
  color: #dadada;
  border: #ff411f solid 1px;
}
.follow-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #ffffff;
}
.follow-badge {
  background: rgba(255, 11, 185, 0.06);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #000000;
}
.show-btn {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ff00d6;
}
.tab-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
/* Styling for active tab */
.tab-header_active {
  color: #ff0bb9;
}
.tab-header:hover {
  color: #ff0bb9;
}
</style>
