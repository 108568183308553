<template>
  <div class="mt-4 row">
    <div class="col-md-4">
      <impression />
      <edit-bio-modal
        :section="section"
        ref="editBioModal"
        @update-active="updateActive"
      ></edit-bio-modal>
      <div
        id="about"
        class="d-flex flex-column about-card align-items-center justify-content-end"
      >
        <a href="#info" class="tab-header">Personal Info</a>
        <a href="#address" class="tab-header">Address </a>
        <a href="#education" class="tab-header">Education </a>
        <a href="#job" class="tab-header">Job </a>
      </div>
    </div>
    <div class="col-md-8 about-section">
      <div id="info" class="tabcontent pb-3">
        <div class="about-card px-0">
          <div
            class="d-flex align-items-center justify-content-between"
            style="border-bottom: 3px solid #eaedf2; padding: 12px"
          >
            <h2 class="card-header-text">Personal Info</h2>
            <button
              class="edit-btn text-primary"
              @click="openBioModal('personalInfo')"
              v-if="user && user.id == profile.id"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Name:</h2>
            </div>
            <div class="">
              <h2 class="user-name">
                {{ profile.first_name }} {{ profile.last_name }}
              </h2>
            </div>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Gender:</h2>
            </div>
            <div class="">
              <h2 class="user-name">{{ profile.gender }}</h2>
            </div>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Date of birth:</h2>
            </div>
            <div class="">
              <h2 class="user-name">{{ profile.dob }}</h2>
            </div>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Joined:</h2>
            </div>
            <div class="">
              <h2 class="user-name">
                {{ moment(profile.created_at).fromNow() }}
              </h2>
            </div>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Email:</h2>
            </div>
            <div class="">
              <h2 class="user-name">
                {{ profile.email }}
                <span
                  v-if="profile.email_verified_at != null"
                  class="text-success ms-3"
                >
                  verified <i class="bi bi-patch-check-fill"></i
                ></span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div id="address" class="tabcontent py-3">
        <div class="about-card px-0">
          <div
            class="d-flex align-items-center justify-content-between"
            style="border-bottom: 3px solid #eaedf2; padding: 12px"
          >
            <h2 class="card-header-text">Address</h2>
            <button
              class="edit-btn text-primary"
              @click="openBioModal('address')"
              v-if="user && user.id == profile.id"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">City:</h2>
            </div>
            <div class="">
              <h2 class="user-name">{{ profile.city }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="education" class="tabcontent py-3">
        <div class="about-card px-0">
          <div
            class="d-flex align-items-center justify-content-between"
            style="border-bottom: 3px solid #eaedf2; padding: 12px"
          >
            <h2 class="card-header-text">Education</h2>
            <button
              class="edit-btn text-primary"
              @click="openBioModal('education')"
              v-if="user && user.id == profile.id"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">University:</h2>
            </div>
            <div class="">
              <h2 class="user-name">{{ profile.university }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="job" class="tabcontent py-3">
        <div class="about-card px-0">
          <div
            class="d-flex align-items-center justify-content-between"
            style="border-bottom: 3px solid #eaedf2; padding: 12px"
          >
            <h2 class="card-header-text">Job</h2>
            <button
              class="edit-btn text-primary"
              @click="openBioModal('job')"
              v-if="user && user.id == profile.id"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
          <div class="user-card">
            <div class="">
              <h2 class="name-text">Position:</h2>
            </div>
            <div class="">
              <h2 class="user-name">{{ profile.occupation }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { Modal } from "bootstrap"
import moment from "moment"
import impression from "@/components/Impression.vue"
import EditBioModal from "./editBio/EditBioModal.vue"

export default {
  name: "About",
  components: {
    impression,
    EditBioModal
  },
  data() {
    return {
      section: "personalInfo"
    }
  },
  created() {
    this.moment = moment
  },
  computed: {
    ...mapState("user", ["user", "profile"])
  },
  methods: {
    openBioModal(section) {
      this.section = section
      const modal = new Modal(this.$refs.editBioModal.$refs.bioModal)
      modal.show()
    },
    updateActive(tab) {
      this.section = tab
    }
  }
}
</script>

<style scoped>
.about-card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-top: 1rem;
  padding: 12px;
}
.tab-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  background: #ffffff;
  padding: 12px;
  width: 100%;
  text-align: justify;
  margin-top: 1rem;
}
/* Styling for active tab */
.tab-header:active {
  color: #ff0bb9;
}
.tab-header:hover {
  color: #ff0bb9;
  background: rgba(255, 11, 185, 0.06);
  border-radius: 10px;
}
.edit-btn {
  display: block;
  background: white;
  border: none;
  font-size: 18px;
}
.card-header-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #04295f;
}
.user-card {
  display: grid;
  grid-template-columns: 0.5fr 2fr;
  text-align: justify;
  padding: 12px;
}
.name-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #04295f;
}
.about-section {
  height: calc(100vh - 230px);
  overflow-y: auto;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>
