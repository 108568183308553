<template>
  <div class="col-md-12 col-lg-9">
    <div class="row">
      <div class="bg-white rounded-3 shadow-sm p-0">
        <profile-cover v-if="profile"></profile-cover>
        <div
          class="d-flex flex-column flex-lg-row justify-content-between align-items-center px-4 py-3 py-lg-0"
        >
          <!-- profile image -->
          <div
            class="d-flex align-items-center justify-content-center flex-row mb-3"
          >
            <profile-avatar v-if="profile"></profile-avatar>
            <!-- user details -->
            <div class="user-details ms-2" v-if="profile">
              <h1 class="user-name text-center">
                {{ profile.first_name }} {{ profile.last_name }}
                <i
                  class="bi bi-patch-check-fill text-success ms-1"
                  v-if="profile.email_verified_at != null"
                ></i>
              </h1>
            </div>
          </div>
          <!-- profile tabs -->
          <div class="tabs">
            <a
              @click="setActiveTab(1)"
              class="mx-2 mx-md-3 mx-lg-3 tab-header"
              :class="activeTab == 1 && 'tab-header_active'"
            >
              Post
            </a>
            <a
              @click="setActiveTab(2)"
              class="mx-2 mx-md-3 mx-lg-3 tab-header"
              :class="activeTab == 2 && 'tab-header_active'"
            >
              About
            </a>
            <a
              @click="setActiveTab(3)"
              class="mx-2 mx-md-3 mx-lg-3 tab-header"
              :class="activeTab == 3 && 'tab-header_active'"
            >
              Following
            </a>
            <a
              @click="setActiveTab(4)"
              class="mx-2 mx-md-3 mx-lg-3 tab-header"
              :class="activeTab == 4 && 'tab-header_active'"
            >
              Followers
            </a>
            <a
              @click="setActiveTab(5)"
              class="mx-2 mx-md-3 mx-lg-3 tab-header"
              :class="activeTab == 5 && 'tab-header_active'"
            >
              Gallery
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- profile tabs content -->
    <div class="row mt-4 align-items-center justify-content-center">
      <div class="col-md-12 col-lg-8 p-0" v-if="activeTab === 1">
        <NewsFeeds />
      </div>
    </div>
    <div class="px-0">
      <div v-if="activeTab === 2" class="tabcontent">
        <About />
      </div>
      <div v-if="activeTab === 3" class="tabcontent">
        <div class="mt-3">
          <followers section="following" />
        </div>
      </div>
      <div v-if="activeTab === 4" class="tabcontent">
        <div class="mt-3">
          <followers section="followers" />
        </div>
      </div>
      <div v-if="activeTab === 5" class="tabcontent">
        <Gallery />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import About from "@/components/profile/About.vue"
import NewsFeeds from "@/components/NewsFeeds.vue"
import Followers from "@/components/profile/ProfileFollows"
import Gallery from "@/components/profile/Gallery"
import ProfileAvatar from "@/components/profile/ProfileAvatar.vue"
import ProfileCover from "@/components/profile/ProfileCover.vue"

export default {
  name: "ProfileHeader",
  components: {
    NewsFeeds,
    Followers,
    About,
    Gallery,
    ProfileAvatar,
    ProfileCover
  },
  data() {
    return {
      activeTab: 1
    }
  },
  computed: {
    ...mapState("user", ["user", "profile"])
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val
    }
  }
}
</script>

<style scoped>
/* --------- user-profile -------- */

/* user-details */
.user-name {
  font-weight: bold;
  font-family: "roboto", "sans-serif";
  font-size: 2rem;
}
.user-location {
  font-size: 1rem;
  font-family: "roboto", "sans-serif";
  font-weight: 400;
}
.followers-container {
  border-right: #0064fa62 solid 1px;
  height: 2rem;
}
.followers-count {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #0066fa;
}
.followers-text {
  margin-top: -1.2rem;
  text-align: center;
}

/* user-deatails-action */
.user-action {
  border-bottom: #eeeeee 2px solid;
  border-top: #eeeeee 2px solid;
}
.user-action-btn {
  border-bottom: #fff;
  outline: none;
}
.user-action-btn:focus {
  border-bottom: #0066fa 4px solid;
  outline: none;
  color: #0066fa;
}
.tab-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
/* Styling for active tab */
.tab-header_active {
  color: #ff0bb9;
}
.tab-header:hover {
  color: #ff0bb9;
}

.edit-profile-btn {
  background-color: #d2e3ff;
}

/* ========= media queries ========= */

/* === mobile === */
@media (max-width: 640px) {
  .cover-img {
    height: 30vh;
    overflow: hidden;
  }
  .user-name {
    font-weight: bold;
    font-family: "roboto", "sans-serif";
    font-size: 1.5rem;
  }
  .tab-header {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }
}

/* === tablet === */

@media (min-width: 768px) {
}

/* === desktop === */

@media (min-width: 1024px) {
  .user-name {
    font-weight: bold;
    font-family: "roboto", "sans-serif";
    font-size: 1.5rem;
  }
}

/* === Larger desktop === */
@media (min-width: 1440px) {
}
</style>
