<template>
  <div class="bg-success rounded-3 cover-img position-relative">
    <div>
      <AdvancedImage
        :cldImg="profileCover"
        :plugins="imgPlugins"
        v-if="profile.cover_photo"
        class="img-fluid rounded-3"
      />
      <input
        type="file"
        style="display: none"
        ref="picInput"
        accept="image/*"
        @change="handleFile"
      />
      <div
        class="d-flex edit-item position-absolute align-items-center"
        @click="$refs.picInput.click()"
        v-if="user.id === profile.id"
      >
        <div
          class="camera-icon rounded-circle px-2"
          style="background-color: white"
        >
          <i class="bi bi-camera-fill fs-5"></i>
        </div>
        <div class="text-light mx-2">Edit Cover</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { getCloudinaryPId } from "@/utils/misc"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity"
import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn"
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder
} from "@cloudinary/vue"
export default {
  name: "ProfileCover",
  components: { AdvancedImage },
  data() {
    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]
    return {
      coverUrl: null,
      imgPlugins
    }
  },
  computed: {
    ...mapState("user", ["user", "profile"]),
    profileCover() {
      const profileCover = this.cld.image(
        getCloudinaryPId(this.profile.cover_photo)
      )
      profileCover
        .format("auto")
        .quality("auto")
        .resize(fill().gravity(focusOn(FocusOn.faces())))
      return profileCover
    }
  },
  methods: {
    ...mapActions("user", ["editUser", "uploadProfileImage"]),
    handleFile(e) {
      this.coverUrl = e.target.files[0]

      let formData = new FormData()
      formData.append("cover_photo", this.coverUrl)
      this.uploadProfileImage({
        type: "cover_photo",
        userId: this.profile.id,
        data: formData
      })
    },
    getCloudinaryPId: getCloudinaryPId
  }
}
</script>

<style scoped>
.cover-img {
  height: 322px;
  overflow: hidden;
}

.edit-item {
  bottom: 8%;
  right: 5%;
  cursor: pointer;
}
</style>
