<template>
  <form action="" class="">
    <div
      class="d-flex align-items-start justify-content-start flex-column mt-3"
    >
      <label class="form-label text-left">City</label>
      <input type="text" class="form-control" />
    </div>
    <button class="btn btn-primary mt-3 px-5 rounded-pill">Save</button>
  </form>
</template>
